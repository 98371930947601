import React from 'react';
import { connect } from 'react-redux';
import Config from './Config';

import './App.css';
import ResponsiveLayout from './Containers/ResponsiveLayout';
import Layout from './Containers/Layout';
import Loading from './Components/Loading';
import Unauthorised from './Components/Unauthorised';
import { localeMapper } from './utils/localeMapper';
import { intercomMessenger } from './utils/intercomMessenger';

const ModalImportExport = React.lazy(() =>
  import('./Containers/ModalImportExport'),
);
const MainMap = React.lazy(() => import('./Containers/MainMap'));
const ModalExample = React.lazy(() => import('./Containers/ModalExample'));
const ModalCreate = React.lazy(() => import('./Containers/ModalCreate'));
const ModalUpload = React.lazy(() => import('./Containers/ModalUpload'));
const ModalOverlayError = React.lazy(() =>
  import('./Containers/ModalOverlayError'),
);
const ModalSiteSelector = React.lazy(() =>
  import('./Containers/ModalSiteSelector'),
);
const Toolbar = React.lazy(() => import('./Components/Toolbar'));

class App extends React.Component {
  componentDidMount = () => {
    this.props.init();
  };

  get intercomPayload() {
    const { auth = {} } = this.props;

    if (!auth) {
      return {};
    }

    return {
      user_id: auth['custom:forwood_uuid'],
      user_hash: auth.hmac,
    };
  }

  render() {
    if (!this.props.auth.username) return <span />;

    if (this.props.auth.unauthorised) {
      return (
        <ResponsiveLayout>
          <Layout>
            <Unauthorised />
          </Layout>
        </ResponsiveLayout>
      );
    }

    return (
      <>
        {
          Config.intercom_enabled.toLowerCase() && intercomMessenger(Config.Auth.cookieStorage.domain, localeMapper(this.props.language.locale), this.intercomPayload, Config.env)
        }
        <ResponsiveLayout>
          <Layout>
            <React.Suspense fallback={<Loading show={true} />}>
              {this.props.showMap && this.props.mapReady && <MainMap />}
              {this.props.showUploadModal && <ModalUpload />}
              {this.props.showCreateModal && <ModalCreate />}
              {this.props.showImportExport && <ModalImportExport />}
              {this.props.showOverlayError && <ModalOverlayError />}
              {this.props.showSiteSelector && <ModalSiteSelector />}
              {this.props.showExample && <ModalExample />}
            </React.Suspense>
            <React.Suspense fallback={<span />}>
              {!this.props.showSiteSelector && <Toolbar />}
            </React.Suspense>
            <Loading
              show={
                !this.props.showSiteSelector &&
                (this.props.loading.UPDATE_GEO_AREA ||
                  this.props.loading.CREATE_GEO_AREA ||
                  this.props.loading.SITE_GEO_AREAS ||
                  this.props.loading.HIERARCHY)
              }
            />
          </Layout>
        </ResponsiveLayout>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    loading: state.loading,
    toolbarReady: state.ui.toolbarReady,
    showUploadModal: state.modal === 'upload',
    showCreateModal: state.modal === 'createGeoArea',
    showSiteSelector:
      state.auth.username &&
      !state.auth.unauthorised &&
      !state.geoAreas.selectedSite &&
      !state.loading.HIERARCHY,
    showImportExport: state.modal === 'importExport',
    showOverlayError: state.mapTiler.status === 'ERROR',
    showMap: !!state.auth.username,
    showExample:
      state.customGeoArea.mode === 'CREATE' || state.modal === 'createGeoArea',
    mapReady: state.map.mapReady,
    language: state.language,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    init(params) {
      dispatch({ type: 'INIT', ...params });
    },
    setToolbarReady() {
      dispatch({ type: 'TOOLBAR_READY' });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
